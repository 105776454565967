<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
      
    <el-row type="flex" justify="center" class="bg">
      <el-col :span="18">
        <el-card class="box-card">

          <template #header>
            <div class="card-header">
              <h3> Completa la registrazione </h3>
            </div>
          </template>

          <el-form label-width="auto" :model="userData" @submit.prevent>
            <el-form-item v-for="(field, index) in fields" :key="index" :label="field.mandatory ? field.label+' (*)' : field.label">
              <el-input v-model="userData[field.name]" :type="field.type" :placeholder="field.placeholder"></el-input>
            </el-form-item>
          </el-form>

          
          <el-button type="primary" @click="submit">Conferma</el-button>

        </el-card>
      </el-col>
    </el-row>

    <el-footer>
        <Footer></Footer>
    </el-footer>
  </el-container>   
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "ActivateUser",
  components: {Header, Footer},
  data() {
    return {
      userDescriptionFields: [],
      userData: {
        email: "",
        name: "",
        surname: ""
      },
      fields: []
    }
  },
  computed: {
    ...mapGetters(["appUser", "currentUser", "isLogged"])
  },
  methods: {
    ...mapActions(["fetchUser", "loadPageUser", "updateUser", "fetchUserByHash"]),
    async submit() {
      await this.updateUser({ ...this.userData });
      this.$message.success('Registrazione quasi completata, conferma l\'email');
      this.$router.push({name: "VerifyToken", params: {id: this.currentUser.id} })
    },

    createFields() {
      this.fields = [
        {
          name: "email",
          label: "Email",
          mandatory: true, 
          type: "email"
        },
        {
          name: "name",
          label: "Nome",
          type: "text"
        },
        {
          name: "surname",
          label: "Cognome",
          type: "text"
        },
      ]
      
      this.userData.name = this.currentUser.name
      this.userData.surname = this.currentUser.surname
    },
  },
  async created() {
    if (this.isLogged){
      this.$router.push({name: "dashboard"})
    }
    const userHash = this.$route.params.hash;
    await this.fetchUserByHash(userHash);
    this.createFields()
  }
}
</script>

<style scoped>

  .bg {
    height: 100vh;
    background: #ededed;
    width: 100%;
    align-items: center;
  }

</style>